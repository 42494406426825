.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.352);
}

.modal {
  position: fixed;
  top: calc(50vh - 25vh);
  width: 50vw;
  height: 50vh;
  left: calc(50% - 25vw);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 2s ease-out forwards;
  background: rgba(255, 255, 255, 0.806);
  -webkit-backdrop-filter: blur(13px);
  backdrop-filter: blur(13px);
  border: 1px solid rgba(255, 255, 255, 0.125);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: 1px solid black;
  outline-offset: -3px;
}

.close {
  cursor: pointer;
  font: inherit;
  border: none;
  font-size: 1.4vw;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.3s;
  color: #585858;
  position: absolute;
  top: 2.5%;
  right: 2.5%;
}

.close:hover,
.close:active {
  background-color: #000000;
  color: white;
}

/* @media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
} */

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-7rem);
  }
  25% {
    opacity: 0;
    transform: translateY(-7rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 34em) {
  .modal {
    top: calc(50vh - 18vh);
    height: 36vh;
    width: 70vw;
    left: calc(50% - 35vw);
  }
  .close {
    font-size: 4vw;
  }
}
