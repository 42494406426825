.container {
  text-align: center;
  font-size: 1.2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(111, 111, 111);
  gap: 3rem;
  line-height: 1.25;
}

.hayberya {
  font-size: 3vw;
  color: #000;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 5rem;
}

.button {
  cursor: pointer;
  font: inherit;
  border: none;
  font-size: 1.4vw;
  padding: 0.5rem 2.5rem;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.3s;
  border: 1px solid black;
  color: #404040;
}

.button:hover,
.button:active {
  background-color: #000000;
  color: white;
}

.country-info {
  font-size: 2.4vw;
}

.country {
  color: #111;
  font-size: 2.8vw;
  font-weight: 500;
}

@media (max-width: 34em) {
  .container {
    font-size: 4vw;
  }
  .hayberya {
    font-size: 5vw;
  }
  .country-info {
    font-size: 4vw;
  }
  .country {
    font-size: 4vw;
  }
  .button-container {
    margin-top: 3rem;
  }
  .button {
    font-size: 3vw;
  }
}
