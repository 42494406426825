.loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  z-index: 999;
  animation: fadeout 2s ease-out;
}

.spinner {
  border: 4px solid rgba(32, 32, 32, 0.658);
  border-top-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes fadeout {
  0% {
    background-color: rgb(255, 255, 255);
  }
  50% {
    background-color: rgb(255, 255, 255);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}
