@font-face {
  font-family: "Normal";
  src: url("./assets/font/GeneralSans-Variable.ttf") format("truetype");
}
@font-face {
  font-family: "Heading";
  src: url("./assets/font/Switzer-Variable.ttf") format("truetype");
}
:root {
  --primary-color: #ffff;
  --nav-container-width: 90%;
  --nav-height: 8rem;
  --nav-border: 1px solid rgb(0, 0, 0);
  --nav-border-light: 0.5px solid rgb(0, 0, 0);
  --general-font: "Normal", sans-serif;
  --logo-font: "Kolos", serif;
  --logo-color: rgb(35, 30, 33);
  --sans-serif-font: "Tenor Sans", sans-serif;
  --margin-bottom: 22vh;
  --border-color: #fefefe84;
  --border-radius: 5px;
  --site-width: 72.5%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
*:focus {
  outline: none;
  outline: 1px solid var(--border-color);
  border-radius: 1px;
  outline-offset: 1rem;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: var(--general-font);
  line-height: 1;
  font-weight: 300;
  color: #111;
  background-color: #fefefe;
}

::selection {
  background-color: rgb(199, 199, 199);
}

.container {
  margin: 0 auto;
}

.hidden {
  opacity: 0;
  transition: transform 2s ease 0.2s, opacity 2s ease 0.2s;
  transform: translateY(5rem);
}

.hidden-slow {
  opacity: 0;
  transition: transform 6s ease 0.2s, opacity 4s ease 0.2s;
  transform: translateY(8rem);
}

.show {
  opacity: 1;
  transform: translateY(0rem);
}
.welcome-screen {
  width: 100%;
  height: 100%;
  animation: welcome 2s ease;
  animation-fill-mode: both;
}

@keyframes welcome {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #010101;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  display: inline-block;
  text-align: center;
  position: relative;
  font-family: var(--serif-font);
  font-size: 10vw;
  color: #222;
}

.loader-fade-out {
  animation: loaderfadeout 3s ease-out;
}
.spinner {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: #c40000;
  -webkit-mask: radial-gradient(
    circle closest-side at 50% 40%,
    #0000 94%,
    #000
  );
  transform-origin: 50% 40%;
  animation: spinner-pl92zb 1s infinite linear;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button {
  pointer-events: auto;
  cursor: pointer;
  background: #ffffff;
  border: none;
  padding: 1.5rem 3rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  font-size: 0.8vw;
  border: 1px solid #000;
}

/* section hero */
/* section hero */
/* section hero */

#section-hero {
  height: 72.5rem;
  width: 100%;
  position: relative;
}
.navigation {
  height: var(--nav-height);
  background-color: #fefefe;
  width: 100%;
  flex-direction: column;
  border-bottom: var(--nav-border-light);
}
.nav-container {
  width: var(--site-width);
  justify-content: space-between;
}

.nav-second-container {
  width: var(--site-width);
}

.nav-second {
  width: 100%;
  height: calc(var(--nav-height) / 2);
  background-color: #fefefe77;
  border-bottom: var(--nav-border);
}

.nav-second a {
  border-left: var(--nav-border-light);
  border-right: var(--nav-border-light);
  padding: 0 6vw 0 6vw;
}

.nav-second a:first-child {
  border-left: none;
  border-right: var(--nav-border-light);
}

.nav-second a:last-child {
  border-right: none;
  border-left: var(--nav-border-light);
}

.logo {
  width: 10rem;
}

.nav-link:link,
.nav-link:visited {
  font-size: 1vw;
  font-weight: 500;
  text-decoration: none;
  transition: transform 0.3s;
  color: #111;
}

.nav-link:hover,
.nav-link:active {
  text-decoration: underline;
}
.nav-link .active {
  transform: scale(1.5);
}

.globe-container {
  gap: 0.75rem;
}
.globe {
  width: 2.4rem;
}

.main-heading {
  text-align: center;
  font-size: 5vw;
  color: #000000;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  top: 32.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-family: "Heading", sans-serif;
  font-weight: 600;
  width: 100%;
}

.header-container {
  flex-direction: column;
  gap: 30vh;
  height: 72.5rem;
  width: var(--site-width);
}

/* SECTION LOG */
/* SECTION LOG */
/* SECTION LOG */
/* SECTION LOG */
/* SECTION LOG */

.img-container-container {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: -10vw;
}

.img-container {
  height: 70%;
  overflow: hidden;
  filter: grayscale(100%);
  transition: all 0.5s;
}

.img-container img {
  width: 100%;
}

.img-container:hover {
  transform: scaleX(1.05);
  filter: grayscale(0%);
  z-index: 5;
}
/* SECTION Policy */
/* SECTION Policy */
/* SECTION Policy */
/* SECTION Policy */
/* SECTION Policy */

.section-policy {
  height: 70rem;
  width: 100%;
  background-image: linear-gradient(to top, #e6e9f060 0%, #eef1f54d 100%);
  position: relative;
}

.policy-container {
  flex-direction: column;
  gap: 2rem;
  width: calc(var(--site-width) / 2);
  text-align: center;
  height: 100%;
}

.policy-heading-container {
  font-size: 4vw;
}
.policy-heading {
  font-family: "Heading", sans-serif;
  font-weight: 600;
}

.policy-text {
  line-height: 1.5;
  font-size: 1.35vw;
}
/* SECTION Footer */
.section-footer {
  border-top: var(--nav-border);
}

.nav-container {
  width: var(--site-width);
  text-align: center;
  padding: 1rem 0 1rem 0;
}

.copyright {
  width: 100%;
  text-align: center;
  font-size: 1.2vw;
  font-weight: 500;
}

/* SECTION FEATURED */
/* SECTION FEATURED */
/* SECTION FEATURED */
/* SECTION FEATURED */

.featured-container {
  background-image: url(./assets/img/hayberya5.jpg);
  background-position: center;
  background-size: cover;
  height: 90vh;
}
.featured-container-2 {
  background-image: url(./assets/img/hayberya333.png);
}
.featured-container-3 {
  background-image: url(./assets/img/hayberya1landscape.png);
}
.featured-cover {
  width: 100%;
  height: 90vh;
  border: 50px solid white;
  border-bottom: 0;
  /* outline-offset: -50px;
  outline: 10px lin black; */
  background: conic-gradient(
    from 90deg at 45% 50%,
    rgba(0, 0, 0, 0.8),
    rgba(255, 255, 255, 0)
  );
  position: relative;
}
.fc2 {
  background: conic-gradient(
    from 270deg at 55% 50%,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.8)
  );
}
.featured-heading {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translateY(-50%);
  font-size: 5vw;
  font-family: "Heading", sans-serif;
  font-weight: 600;
  color: #ffffff;
}
.fh2 {
  top: 50%;
  left: 55%;
  transform: translate(-100%, -100%);
}
.featured-description-text {
  position: absolute;
  top: 52.5%;
  left: 45%;
  color: #fefefe;
  font-size: 1.6vw;
  line-height: 1.5;
  width: 45%;
}
.fdt2 {
  left: 10%;
  text-align: right;
}

/* SECTION CAREERS */
/* SECTION CAREERS */
/* SECTION CAREERS */
/* SECTION CAREERS */

.section-careers {
  padding: 10rem 0 10rem 0;
  border-top: 1px solid black;
  border-bottom: 1px solid rgb(241, 241, 241);
  flex-direction: column;
  gap: 10rem;
}

.careers-heading {
  font-family: "Heading", sans-serif;
  font-size: 5vw;
  font-weight: 500;
}

/* SECTION SURGERY */
/* SECTION SURGERY */
/* SECTION SURGERY */
/* SECTION SURGERY */

.section-surgery {
  padding: 10rem 0 10rem 0;
  border-top: 1px solid black;
  border-bottom: 1px solid rgb(241, 241, 241);
  flex-direction: column;
  gap: 4rem;
}

.surgery-heading {
  font-family: "Heading", sans-serif;
  font-size: 3.5vw;
  font-weight: 500;
}
.surgery-container {
  width: 70%;
  text-align: center;
  line-height: 1.4;
  font-size: 1.6vw;
}

.surgery-desc {
  flex-direction: column;
  gap: 4rem;
  font-size: 2.7vw;
}

.surgery-types-container {
  gap: 4vw;
  font-size: 1.6vw;
  font-weight: bold;
  text-decoration: underline;
  align-items: center;
  justify-content: center;
}
.call-us {
  font-size: 1.6vw;
  text-align: center;
}

/* SECTION Footer */
/* SECTION Footer */
/* SECTION Footer */
/* SECTION Footer */

.footer-logo {
  width: 30rem;
}

.footer-cr {
  height: 6rem;
  border-top: 0.5px solid black;
  font-size: 1.2vw;
  text-align: center;
}
/* ERROR */

.error-container {
  flex-direction: column;
  height: 100vh;
  text-align: center;
  font-size: 3vw;
}

.error-content-container {
  flex-direction: column;
  gap: 3rem;
}

.logo-error {
  width: 30rem;
}

/* SECTION SERVICES */
/* SECTION SERVICES */
/* SECTION SERVICES */
/* SECTION SERVICES */

.section-services {
  padding: 5rem 0 10rem 0;
  flex-direction: column;
  gap: 5rem;
}

.section-services-heading {
  font-size: 16vw;
  margin-bottom: -8.5vw;
  color: #e7e7e7;
}

.to-navigate {
  margin-top: 0rem;
  font-size: 1vw;
}

.services-container {
  gap: 5rem;
  font-size: 4vw;
}

.services-link:link,
.services-link:visited {
  color: #000;
  text-decoration: underline;
  transition: all 0.3s;
}

.services-link:hover,
.services-link:active {
  text-decoration: none;
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: #ffffff53 !important;
  animation: nav 1s;
}
@keyframes nav {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sticky-second {
  height: 3rem !important;
}

.active:link,
.active:visited {
  text-decoration: underline;
  font-weight: 600;
}

.parallax {
  position: absolute;
  font-size: 20vw;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f0f0f0;

  width: 100%;
  text-align: center;
}

@media (max-width: 48em) {
  .nav-link:link,
  .nav-link:visited {
    font-size: 1.4vw;
  }
  .button {
    font-size: 1.2vw;
  }

  .policy-container {
    gap: 1rem;
    width: calc(var(--site-width) / 1.5);
  }

  .policy-heading-container {
    font-size: 5vw;
  }

  .policy-text {
    line-height: 1.5;
    font-size: 2vw;
  }

  .featured-heading {
    font-size: 7vw;
  }
  .featured-description-text {
    font-size: 2.2vw;
  }
  .footer-cr {
    font-size: 2vw;
  }
}
@media (max-width: 34em) {
  .nav-link:link,
  .nav-link:visited {
    font-size: 3.4vw;
  }
  .button {
    font-size: 3vw;
  }
  .nav-second {
    height: calc(var(--nav-height) / 1.2);
  }

  .nav-second-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 1.6vw;
  }
  .nav-second a {
    padding: 0 !important;
  }
  .nav-second-container a:link {
    text-align: center;
  }
  .nav-second-container a:first-child:link {
    grid-column: 1/3;
  }
  .nav-second-container a:nth-child(2):link {
    grid-column: 3/5;
  }
  .nav-second-container a:nth-child(3):link {
    grid-column: 5/7;
  }
  .nav-second-container a:nth-child(4):link {
    border-left: none;
    grid-column: 1/4;
  }
  .nav-second-container a:nth-child(5):link {
    grid-column: 4/7;
  }
  .section-services-heading {
    font-size: 25vw;
    margin-bottom: -8.5vw;
    text-align: center;
  }
  .services-container {
    width: 70%;
    margin: 0 auto;
    gap: 3rem;
    font-size: 6vw;
    text-align: center;
  }
  .to-navigate {
    font-size: 4vw;
  }
  .main-heading {
    font-size: 10vw;
    width: 120% !important;
  }

  .featured-cover {
    background: conic-gradient(
      from 90deg at 30% 50%,
      rgba(0, 0, 0, 0.8),
      rgba(255, 255, 255, 0)
    );
  }
  .fc2 {
    background: conic-gradient(
      from 270deg at 70% 50%,
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.8)
    );
  }
  .featured-heading {
    top: 47%;
    left: 30%;
    transform: translateY(-50%);
    font-size: 7vw;
  }
  .fh2 {
    top: 47%;
    left: 10%;
    width: 100%;
    font-size: 6vw;
    transform: translateY(-50%);
  }

  .featured-description-text {
    top: 51.5%;
    left: 30%;
    font-size: 3.6vw;
    line-height: 1.3;
    width: 60%;
  }

  .section-policy {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10vw;
    height: max-content;
    padding: 25vw 0 15vw 0;
  }
  .policy-container {
    height: max-content;
    width: 80%;
  }
  .policy-heading {
    font-size: 10vw;
  }
  .policy-text {
    font-size: 4.4vw;
    line-height: 1.2;
  }
  .parallax {
    top: 10%;
  }
  .section-surgery {
    padding: 10rem 0 10rem 0;
    border-top: 1px solid black;
    border-bottom: 1px solid rgb(241, 241, 241);
    flex-direction: column;
    gap: 4rem;
  }

  .surgery-heading {
    text-align: center;
    font-size: 8vw;
  }
  .surgery-container {
    width: 75%;
    text-align: center;
    font-size: 3vw;
  }

  .surgery-desc {
    font-size: 5vw;
  }

  .surgery-types-container {
    font-size: 5vw;
    flex-direction: column;
  }
  .call-us {
    font-size: 4vw;
  }
}

@media (max-width: 28em) {
  .section-services-heading {
    font-size: 25vw;
    margin-bottom: -25vw;
    text-align: center;
  }

  .featured-cover {
    background: conic-gradient(
      from 90deg at 20% 40%,
      rgba(0, 0, 0, 0.8),
      rgba(255, 255, 255, 0)
    );
  }
  .fc2 {
    background: conic-gradient(
      from 270deg at 80% 40%,
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.8)
    );
  }
  .featured-heading {
    top: 37%;
    left: 20%;
    font-size: 5vw;
    width: 100%;
  }
  .fh2 {
    top: 37%;
    left: 7%;
    width: 100%;
  }

  .featured-description-text {
    top: 41.5%;
    left: 20%;
    font-size: 3.6vw;
    line-height: 1.2;
  }
  .footer-cr {
    font-size: 3vw;
  }
}
